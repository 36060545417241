/* You can add global styles to this file, and also import other style files */
@use '~@angular/material' as mat;
@import 'theme';
@import 'material';
@import 'normalize';
@import '/projects/pw-lib/src/styles/pw-layout.scss';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500&display=swap');

html {
  // font-family: 'Noto Sans JP';
  font-family: 'Noto Sans KR';
}

// 모바일 앱의 경우
.mobile-app {
  * {
    // 링크 롱 터치 불가
    -webkit-touch-callout: none;
  }
}

// 크롬 스크롤 복원용 래퍼
main[wrapper] {
  min-height: 100vh;
  background-color: #fafbff;
  // background-color: #fff;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

// ios safearea top, bottom, left, right
[safearea] {
  @extend [safe-top];
  @extend [safe-bottom];
  @extend [safe-side];
}

// ios safearea top
[safe-top] {
  @supports (padding-top: constant(safe-area-inset-top)) {
    padding-top: constant(safe-area-inset-top);
  }
  @supports (padding-top: env(safe-area-inset-top)) {
    padding-top: env(safe-area-inset-top);
  }
}

// ios safearea bottom
[safe-bottom] {
  @supports (padding-top: constant(safe-area-inset-top)) {
    padding-bottom: constant(safe-area-inset-bottom);
  }
  @supports (padding-top: env(safe-area-inset-top)) {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

// ios safearea left, right
[safe-side] {
  @supports (padding-top: constant(safe-area-inset-top)) {
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
  }
  @supports (padding-top: env(safe-area-inset-top)) {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

[separator] {
  border-top: 1px solid $separator-color;
  width: 100%;
}

mat-date-range-input[separator] {
  border-top: none;
}

[spacer] {
  width: 100%;
  height: 8px;
  background-color: transparent;
}

.dialog-panel {
  max-width: 80vw;
  max-height: calc(100vh - 48px);

  .mat-dialog-container {
    border-radius: 16px;
  }
}

.dialog-backdrop {
  background-color: transparent;
}

.fullscreen-modal {
  width: 100vw;
  height: 100vh;

  .mat-dialog-container {
    border-radius: 0;
    padding: 0;
  }
}

.bluetooth-device-picker {
  min-width: 72% !important;
  min-height: 30%;
  .mat-dialog-container {
    height: auto;
    border-radius: 16px;
  }
}

.amt-input-modal {
  width: 100vw;
  height: 100vh;

  & > .mat-dialog-container {
    border-radius: 0;
    padding: 0;
  }

  @media (orientation: landscape) {
    width: auto;
    height: auto;

    & > .mat-dialog-container {
      box-shadow: none;
      padding: 0;
      border-radius: 0;
      overflow: visible;
      background-color: transparent;
    }
  }
}

.border-round-modal {
  max-width: 90vw;

  .mat-dialog-container {
    padding: 16px;
    border-radius: 16px;
  }

  @media (orientation: landscape) {
    max-width: 60vw;
    .mat-dialog-container {
      padding: 60px 60px;
      padding-top: 56px;
      border-radius: 4px;
    }
  }
}

.mat-card-header-text {
  margin: 0 !important;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(80, 88, 108, 0.3);
}

mat-checkbox {
  .mat-checkbox-inner-container {
    @media (orientation: landscape) {
      width: 18px;
      height: 18px;
    }
  }

  .mat-checkbox-label {
    @media (orientation: landscape) {
      font-size: 20px;
    }
  }

  &.mat-checkbox-checked {
    .mat-checkbox-label {
      @media (orientation: landscape) {
        color: #408e27;
        font-weight: 500;
      }
    }
  }
}

mat-radio-group[single] {
  mat-radio-button {
    .mat-radio-label {
      line-height: 1.2;
    }
  }
}

mat-radio-button {
  .mat-radio-label {
    line-height: 0.875rem;
  }

  .mat-radio-container,
  .mat-radio-outer-circle,
  .mat-radio-inner-circle {
    // 라디오버튼 사이즈 조정
    width: 0.875rem;
    height: 0.875rem;

    @media (orientation: landscape) {
      width: 18px;
      height: 18px;
    }
  }

  .mat-radio-label-content {
    @media (orientation: landscape) {
      font-size: 20px;
    }
  }

  &.mat-radio-checked {
    .mat-radio-label-content {
      // 체크된 버튼 라벨 폰트 색상
      color: mat.get-color-from-palette($primary, 500);

      @media (orientation: landscape) {
        color: #408e27;
        font-weight: 500;
      }
    }
  }
}

mat-slide-toggle {
  .mat-slide-toggle-bar {
    background-color: #c7cad3;
  }
  .mat-slide-toggle-thumb {
    background-color: #fff;
  }
}

mat-list.mat-list-base {
  padding-top: 10px;
  mat-list-item.mat-list-item.mat-2-line {
    height: 64px;
    .mat-list-item-content {
      padding-left: 22px;
      padding-right: 20px;
    }
  }
}

zxing-scanner {
  width: 100%;
  height: 100%;
  video {
    width: 100% !important;
    height: 100% !important;
  }
}

.mat-checkbox {
  color: #464646;
}

.mat-radio {
  color: #464646;
}

.mat-checkbox-checked {
  font-weight: bold;
  color: mat.get-color-from-palette($primary, 500);
}

.mat-radio-checked {
  font-weight: bold;
  color: mat.get-color-from-palette($primary, 500);
}

app-goods-opt-list {
  .goods-opt-continer {
    padding: 16px;
  }

  .goods-opt-wrapper {
    border-top: 1px solid $separator-color;
  }

  .goods-opt-wrapper:first-child {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }

  app-goods-opt {
    .goods-opt-desc {
      color: $font-color-lighter;

      &.opt-error {
        color: mat.get-color-from-palette(mat.$red-palette, 500);
      }
    }
    .mat-radio-button {
      width: 100%;
      .mat-radio-label {
        width: 100%;
        .mat-radio-container {
          margin-bottom: auto;
        }
        .mat-radio-label-content {
          width: 100%;
        }
      }
    }
    .mat-checkbox-layout {
      width: 100%;
      .mat-checkbox-inner-container {
        margin-left: 0;
        margin-top: 4px;
      }
      .mat-checkbox-label {
        width: 100%;
      }
    }
  }
}

app-cart {
  app-goods-table {
    .table-header {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      background-color: $light-gray-green;
      @media (orientation: landscape) {
        font-size: 16px !important;
      }
    }

    .table-body {
      // 뷰포트 - 헤더 - 헤더보더 - 패딩 - 테이블헤더 - 테이블마진 - 버튼 - 결제금액
      height: calc(100vh - 56px - 2px - 32px - 38px - 10px - 80px - 42px);
      @media (orientation: landscape) {
        height: auto;
        .table-row {
          font-size: 14px !important;
          &:first-child {
            font-weight: bold;
          }
        }
      }
    }
  }
}

app-payment {
  app-goods-table {
    .table-header {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      background-color: $light-gray-green;
      font-size: 16px !important;
    }
    .table-body {
      .table-row {
        font-size: 14px !important;
        &:first-child {
          font-weight: bold;
        }
      }
    }
  }
  button .mat-button-wrapper {
    @media (orientation: landscape) {
      font-size: 20px !important;
    }
  }
}

app-ordr-detail {
  app-goods-table {
    .table-header {
      // border-top: 1px solid $separator-color;
      border-bottom: 1px solid $separator-color;
    }
  }
}

app-delng-detail {
  app-goods-table {
    .table-header {
      border-top: 1px solid $separator-color;
      border-bottom: 1px solid $separator-color;
    }
    .table-body {
      position: static !important;
    }
  }
}

app-stats {
  app-joyfood-tabs {
    .mat-tab-group {
      height: 100%;
      overflow: hidden;
      .mat-tab-labels {
        .mat-tab-label {
          height: 56px;
          font-size: 20px;
        }
      }
      .mat-tab-body-wrapper {
        padding-top: 56px;
        height: 100%;
        .mat-tab-body.mat-tab-body-active {
          overflow: hidden;
          .mat-tab-body-content {
            overflow: hidden;
          }
        }
      }
    }
  }
}

.cash-form-panel {
  .mat-dialog-container {
    padding: 56px;
  }
}

@mixin input-box {
  padding: 0.875rem 20px;
  border: solid 0.5px $separator-color;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(217, 217, 217, 0.16);
}

.input-box {
  @include input-box;
}

.select-box {
  @include input-box;
  background-image: url(/assets/imgs/ic_dropdown.svg);
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: calc(100% - 20px);
  background-size: 20px 10px;

  mat-select {
    .mat-select-arrow {
      border: none;
    }
  }

  .mat-select-placeholder {
    color: $font-color-lighter;
  }

  .mat-option-text,
  .mat-select-value-text {
    color: $font-color-light;
  }
}

.point-button {
  .mat-button-wrapper {
    @media screen and (max-width: 599px) {
      font-size: 0.75rem;
    }
  }
}
